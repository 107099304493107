<template>
    <div class="feed-card elevate px-2 px-md-3 pt-2 pb-4 mb-3">

        <div class="d-flex align-items-center mb-2">
            <div class="profilepic mr-1 mr-sm-2" />
            <div class="name" />
        </div>

        <div class="post-body">
            <div class="text" />
            <div class="image" />
        </div>
    </div>
</template>

<script>

export default {
    data () {
        return {}
    }
}
</script>


<style lang="scss" scoped>
    .feed-card {
        background-color: var(--color-cases-content-bg);


        @media screen and (min-width: 576px) {
            border-radius: 8px;

            box-shadow: 0px 4px 12px var(--color-cases-shadow-soft),
                        0px 0px 1px var(--color-cases-shadow-border);

        }
    }

    .post-body {
        width: 100%;
        max-width: 504px;
        margin: 0 auto;
    }

    .profilepic {
        width: 48px;
        height: 48px;
        border-radius: 4px;
        overflow: hidden;
        flex-shrink: 0;
        border-radius: 50%;
        background-color: var(--color-cases-gray-5);
    }

    .name,
    .title,
    .text {
        background-color: var(--color-cases-gray-5);
        display: block;
        border-radius: 4px;
    }

    .name {
        height: 24px;
        width: 60%;
    }


    .title {
        height: 24px;
        width: 70%;
        margin: 8px 0;
    }


    .text {
        height: 16px;
        width: 100%;
        margin-top: 4px;
    }

    .image {
        margin-top: 16px;
        position: relative;
        padding-bottom: 56.25%;
        overflow: hidden;
        background-color: var(--color-cases-gray-5);
        border-radius: 8px;
    }
</style>
