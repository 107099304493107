import Like from '~/components/reactions/Like.vue';
export default {
  props: ['likes', 'liked', 'model', 'id'],
  components: {
    Like: Like
  },
  computed: {
    usersListStore: function usersListStore() {
      return useUsersListStore();
    }
  },
  methods: {
    showLikes: function showLikes() {
      this.usersListStore.update({
        id: "feed-likes-".concat(this.id),
        url: '/user/getlikes',
        title: 'likes',
        params: {
          model: this.model,
          id: this.id
        }
      });
    }
  }
};