import "core-js/modules/es.number.constructor.js";
import debounce from '~/utilities/debounce.js';
export default {
  __name: 'Like',
  props: {
    likes: Number,
    liked: Boolean,
    modelId: String,
    modelName: String
  },
  emits: ['update:liked', 'update:likes'],
  setup: function setup(__props, _ref) {
    var emit = _ref.emit;
    var props = __props;
    var _useNuxtApp = useNuxtApp(),
      $modalityStore = _useNuxtApp.$modalityStore,
      $auth = _useNuxtApp.$auth,
      $socket = _useNuxtApp.$socket;
    var appStore = useAppStore();
    var debounceLike = debounce(function () {
      var lastValue = Object.assign({}, {
        liked: props.liked,
        likes: props.likes
      });
      $socket.post("/like/set", {
        action: props.liked ? 'like' : 'unlike',
        modelId: props.modelId,
        modelName: props.modelName,
        _csrf: appStore.csrfToken
      }, function (data, res) {
        if (res.statusCode != 200) {
          console.error('Error on rection set');
          console.log(res);

          // Undo
          emit('update-liked', lastValue.liked);
          emit('update-likes', lastValue.likes);
        } else {
          emit('update-liked', res.body.liked);
        }
      });
    }, 200);
    var anim = ref(false);
    function toggleLike() {
      if (!$auth.user) {
        return $modalityStore.openModal({
          type: 'drawer',
          name: 'login',
          message: 'login.401.default'
        });
      }
      anim.value = true;
      debounceLike();

      // Update without waiting for server responce
      emit('update-likes', props.liked ? props.likes - 1 : props.likes + 1);
      emit('update-liked', !props.liked);
    }
    return {
      __sfc: true,
      $modalityStore: $modalityStore,
      $auth: $auth,
      $socket: $socket,
      appStore: appStore,
      props: props,
      emit: emit,
      debounceLike: debounceLike,
      anim: anim,
      toggleLike: toggleLike
    };
  }
};